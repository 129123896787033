import React from 'react';

import Layout from '../components/Layout';

const Uses = () => {
  return (
    <Layout title='Uses'>
      <h3>Computer</h3>
      <h3>Audio</h3>
      <h3>Software</h3>
      <h3>Coffee</h3>
    </Layout>
  );
};
export default Uses;